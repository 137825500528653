@import "./scss/style";

html, body, #root {
  height: 100%;
  overflow: auto;
}

.loader {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
