/*
 * Blog Details
 */

.mi-blog-details{
  img{
    margin-bottom: 15px;
  }
  h3{
    font-weight: 700;
  }
  blockquote{
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .blog-body {
    padding: 5rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    max-width: 50rem;

    ol {
      margin-left: 1rem;
    }
  }
}